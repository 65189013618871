body {
    background: linear-gradient(180deg, #1b203d, #3d294d, #3e3e5c);
}

header,
main>section {
    /* height: 100dvh; */
    height: 100vh;
    scroll-snap-align: center;
    scroll-snap-stop: always;
}

main>section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 4rem;
}

@media (min-width: 768px) {
    main>section {
        width: 60%;
        min-width: 40rem;
        max-width: 55rem;
        margin: auto;
    }
}