.sobreMi__textContainer {
    overflow-y: scroll;
    padding: .5rem;
}

.sobreMi__textContainer ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.sobreMi__textContainer li {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}