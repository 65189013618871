.technologie  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    color: #dedede;
    transition: .2s;
}
.technologie img {
    width: 3rem;
    max-width: 3rem;
}
.technologie:hover {
    transform: scale(1.1);
}