.header {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-around;
    gap: 1.5rem;
    padding: 2rem;
    position: relative;

}

@media (min-width: 768px) {
    .header {
        justify-content: space-around;
    }
}

@media (max-height: 570px) {
    .header {
        padding-top: 6rem;
    }
}

h1 {
    white-space: nowrap;
    /* border-right: 3px solid #fff; */
    /* width: 22ch; */
    overflow: hidden;
    color: #fff;
    text-transform: uppercase;
    /* animation: title 3s 1 steps(30), cursor .5s infinite; */
    font-size: 2.8rem;
}
.header__name {
    width: min-content;
    animation-name: name;
    animation-duration: 3s;
}

@keyframes name {
    0% {
        transform: translateY(-5rem);
    }
    100% {
        transform: translateY(0rem);
    }
}

.header__name-btn {
    padding: .5rem;
    border: violet solid .1rem;
    border-radius: .3rem;
    margin-top: 1rem;
    background-color: transparent;
    color: violet;
    font-size: 1rem;
    transition: .2s;
}

.header__name-btn:hover {
    background-color: violet;
    color: #fff;
}

.burger {
    position: fixed;
    border-radius: 50%;
    border: none;
    height: 15rem;
    width: 15rem;
    top: -8rem;
    right: -8rem;
    display: flex;
    align-items: flex-end;
    padding-bottom: 3rem;
    padding-left: 3rem;
    display: none;
    z-index: 2;
}

.h1m {
    display: none;
}

@keyframes title {
    0% {
        width: 0;
    }
}

@keyframes cursor {
    50% {
        border-color: transparent;
    }
}

.header__nav {
    position: fixed;
    width: 100%;
    padding: 2rem;
    top: 0;
    right: 0;
}

.header__nav-ul {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
}

.header__nav-ul a {
    color: #fff;
}

@media (max-width: 814px) {
    .header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .header__nav {
        position: fixed;
        display: none;
        right: 0;
        top: 0;
        width: 200%;
        height: 100vh;
        z-index: 1;
        padding: 0;
        animation-name: nav;
        animation-duration: .5s;
    }

    .header__nav-ul {
        flex-direction: column;
        align-items: flex-end;
        background: linear-gradient(90deg, transparent, #10111a);
        width: 100%;
        height: 100%;
        padding: 1rem;
        padding-top: 8rem;
        gap: 2rem;
        justify-content: flex-start;
    }

    .header__nav-ul {
        font-size: 1.2rem;
    }

    .h1m {
        display: block;
        width: 10ch;
    }

    .h1d {
        display: none;
    }

    .burger {
        display: flex;
    }
}

@media (min-width: 768px) {
    .header__navMobile {
        display: none;
    }
}

@keyframes nav {
    0% {
        right: -100%;
    }

    100% {}
}