.sobreMi {
    gap: 2rem;
}
@media (max-width: 600px) {
    .sobreMi {
        padding: 1rem;
    }
}
@media (min-width: 768px) {
    .sobreMi {
        justify-content: center;
    }
}
.sobreMi h2 {
    color: #fff;
}
.sobreMi__btnContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.sobreMi__btnContainer button {
    padding: .5rem;
    text-transform: uppercase;
    color: violet;
    background-color: transparent;
    border: solid violet .1rem;
    border-radius: .3rem;
    transition: .2s;
}
.sobreMi__textContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #dedede;
    font-size: 1.1rem;
}
/* .sobreMi__tecnoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
} */
.sobreMi__tecnoContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 4rem);
    align-items: center;
    justify-content: center;
    gap: 2rem;
    overflow-y: scroll;
    padding: .5rem;
}