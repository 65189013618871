#contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #fff;
}
#contact label {
    color: #dedede;
}
.contact__social {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.contact__social a {
    color: #fff;
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.contact__social a img{
    width: 1.5rem;
}

#contact form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
#contact form div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
#contact input, #contact textarea{
    padding: .75rem;
    border: none;
    border-radius: .5rem;
    background-color: #2a2036;
    color: #fff;
    outline: none;
}
#contact input::placeholder, #contact textarea::placeholder{
    color: #ddd;
}
#contact textarea {
    width: 100%;
    max-width: 100%;
    max-height: 30rem;
}
.send {
    border: violet solid .1rem;
    background-color: transparent;
    color: violet;
    padding: .75rem;
    border-radius: .5rem;
    transition: .2s;
    text-transform: uppercase;
}
.send:hover {
    background-color: violet;
    color: #fff;
}
.succesfully {
    background-color: #393;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: .75rem;
    border-radius: .5rem;
}